import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { every, find, flatten, intersection, isEqual, sortBy, uniq } from 'lodash'

import { LoadingOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { filterOption, valueToArray } from 'utils'
import s from './SearchSelect.module.scss'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { Icon, Text, Tooltip } from 'components/Primitives'
import { DropdownMenu } from 'components/DropdownMenu'
import { useDebounceEffect, usePrevious } from 'ahooks'
import { TagBadgeNew } from 'components/Badge/TagBadgeNew'
import { Header } from './Header'
import globalMessages from 'components/globalMessages'
import { Filter } from 'hooks/useAvailableFilters'
import { FilterValueTooltipWrapper } from 'components/FilterValueTooltipWrapper'

/**
 * Prefilter the tags to only show relevant ones
 * @param {array of tags} tags
 * @param {array of menu items} options
 * @returns
 */
const preFilterTags = (tags = [], options = []) => {
  // We only want to show tags for items, the user has available in the menu
  const optionTagIds = uniq(flatten(options.map(o => o.tags)))
  const tagIds = tags.map(t => t.id)
  const intersect = intersection(optionTagIds, tagIds)
  const availableTags = tags.filter(t => intersect.includes(t.id))

  const availableTagIds = sortBy(availableTags.map(t => t.id))

  // We only want to show tags, which are really relevant
  // if all option tags are matching the available tags, the tags aren't relevant and don't need to be shown
  const noRelevantTags = every(options.map(o => isEqual(sortBy(o.tags), availableTagIds)))

  return noRelevantTags ? [] : availableTags
}

const getFilterTypeByRole = (role) => {
  switch (role) {
    case 'location-picker':
      return Filter.LOCATION
    case 'weekday-picker':
      return Filter.WEEKDAY
    case 'item-category-1-picker':
      return Filter.ITEM_GROUP_1
    case 'item-category-2-picker':
      return Filter.ITEM_GROUP_2
    case 'item-picker':
      return Filter.ITEM
    default:
      return null
  }
}

export const SearchSelect = ({
  type,
  size,
  mode,
  className,
  prefixIcon,
  placeholder,
  modalTitle,
  options: propsOptions,
  disallowedSections: disallowedPropsSections,
  menuSections,
  value,
  onChange,
  loading,
  optionsLabel,
  required,
  single,
  role,
  allowClear,
  withTooltip,
  tags,
  itemsGroup,
  selectionAsComponent,
  graybg,
  disabled,
  side,
  align = 'start',
  alignOffset = 0,
  sideOffset = 0,
  menuType,
  showMultiTags,
  immediateChange,
  hasError,
  withPortal,
  noHeader,
  noSearch,
  fitWidth, // fit the width of the content to the width of the button (important for Forms)
  dataName,
  name,
  onOpenChange,
  debugMode,
  customTrigger,
  fireInsteadOfSelect, // fire onChange instead of setting internal selection. Good for single item quick selection
  lastUsed,
  ...rest
}) => {
  const intl = useIntl()
  const [selectedKeys, setSelectedKeys] = useState(valueToArray(value))
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [filterText, setFilterText] = useState('')
  // Convert item keys to string
  const options = useMemo(() => loading ? [] : (propsOptions || []).map((item) => ({ ...item, key: item.key.toString() })), [propsOptions, loading])
  const disallowedSections = useMemo(() => loading
    ? []
    : (disallowedPropsSections || []).map(section => ({ ...section, menuItems: section.menuItems.map(item => ({ ...item, key: item.key.toString() })) }))
  , [disallowedPropsSections, loading])
  if (debugMode) {
    console.log('🐞 ~ SearchSelect ~ render', { value, selectedKeys, propsOptions, options })
  }

  const filteredOptions = useMemo(() => options ? options.filter(item => filterOption(filterText, item.label)) : [], [options, filterText])
  const filteredDisallowedSections = useMemo(() => {
    if (disallowedSections.length === 0) return []
    const filtered = disallowedSections.map(section => {
      const filteredItems = section.menuItems.filter(item => filterOption(filterText, item.label))
      return { ...section, menuItems: filteredItems }
    })
    return filtered.filter(i => i.menuItems.length > 0)
  }, [disallowedSections, filterText])
  const [, startTransition] = useTransition()

  const availableTags = useMemo(() => tags ? preFilterTags(tags, options) : null, [tags, options])
  const filteredTags = useMemo(() => tags ? availableTags.filter(tag => tag.name.toLowerCase().includes(filterText.toLowerCase())) : null, [availableTags, filterText])

  const prevValue = usePrevious(value)
  // we have to validate the selected keys with the available options to filter out selected keys, which are not valid
  useDebounceEffect(() => {
    if (isEqual(prevValue, value)) return
    if (!loading) {
      const selectedKeysFromValue = valueToArray(value)
      const validKeys = options.map(o => o.key)

      // only keep valid keys
      const newSelectedKeys = selectedKeysFromValue.filter(k => validKeys.includes(k) || validKeys.includes(k.toString()))
      if (debugMode) console.log('🐞 ~ SearchSelect ~ correct invalid selection', { options, selectedKeysFromValue, newSelectedKeys, selectedKeys })
      if (newSelectedKeys.length != selectedKeys.length || !selectedKeys.every(k => newSelectedKeys.includes(k))) {
        setSelectedKeys(newSelectedKeys)
        // we toggled an option while the component was closed, so let's call the onChange
        if (single) {
          // FIXME: would something break if we make it null instead of ''?
          if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ single picker value changed', newSelectedKeys[0] || '')
          onChange(newSelectedKeys[0] || '')
        } else {
          if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ multi picker value changed', newSelectedKeys)
          onChange(newSelectedKeys)
        }
      }
    }
  }, [loading, options, single], { wait: 300 }) // Well react rules are just anoying, we don't want to react to value or selectedKeys
  // https://react.dev/reference/react/useEffect#reading-the-latest-props-and-state-from-an-effect

  const onToggleTag = (e, id, deselect) => {
    e.preventDefault()
    if (deselect) {
      const newSelectedKeys = selectedKeys.filter(k => !options.find(o => o.key === k).tags.includes(id))
      if (debugMode) console.log('🐞 ~ SearchSelect ~ onToggleTag ~ deselect ~ setSelectedKeys', newSelectedKeys)
      setSelectedKeys(newSelectedKeys)
    } else {
      const newSelectedKeys = uniq([...selectedKeys, ...options.filter(o => o.tags.includes(id)).map(o => o.key)])
      if (debugMode) console.log('🐞 ~ SearchSelect ~ onToggleTag ~ !deselect ~', newSelectedKeys)
      setSelectedKeys(newSelectedKeys)
    }
  }

  const tagItems = useMemo(() => {
    if (!availableTags || !filteredTags || !filteredTags.length) return null

    const tagState = {}
    availableTags.forEach((tag) => {
      tagState[tag.id] = {
        checked: 0,
        unchecked: 0
      }
    })

    options.forEach((o) => {
      const selected = selectedKeys.includes(o.key)
      availableTags.forEach((tag) => {
        if (o.tags.includes(tag.id)) {
          if (selected) {
            tagState[tag.id].checked++
          } else {
            tagState[tag.id].unchecked++
          }
        }
      })
    })

    return filteredTags.map(({ id, name, color, objects_count, assignType }) => {
      const { checked, unchecked } = tagState[id]
      let state = false
      if (checked > 0 && unchecked === 0) {
        state = true
      } else if (checked > 0 && unchecked > 0) {
        state = 'indeterminate'
      }
      return {
        key: id.toString(),
        label: name,
        color,
        objects_count,
        assignType,
        checked: state,
        onClick: (e) => onToggleTag(e, id, !!state)
      }
    })
  }, [filteredTags, availableTags, options, selectedKeys])

  const onToggleOption = useCallback((key, source) => {
    if (single) {
      if (fireInsteadOfSelect) {
        if (debugMode) console.log('🐞 ~ SearchSelect ~ trigger onChange directly', key)
        onChange(key)
      } else {
        if (debugMode) console.log('🐞 ~ SearchSelect ~  setSelectedKeys', key ? [key] : [])
        setSelectedKeys(key ? [key] : [])
      }
      // collapse and clear menu when single select
      if (open) {
        setOpen(false)
        setText('')
      } else {
        // FIXME: Does this work as expected? This is needed for e.g. the Location Picker in the Offering Views for new users, so the values is null and the picker enforces a value itself.
        // we toggled an option while the component was closed, so let's call the onChange
        if (required) {
          if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ single required picker ~ option toggled while component closed ~ key, source', key, source)
          onChange(key)
        }
      }
    } else {
      const wasSelected = selectedKeys.includes(key)
      const newSelectedKeys = key
        ? (wasSelected ? selectedKeys.filter(k => k !== key) : [...selectedKeys, key])
        : []
      if (debugMode) console.log('🐞 ~ SearchSelect ~  setSelectedKeys', newSelectedKeys)
      setSelectedKeys(newSelectedKeys)
    }
  }, [selectedKeys, onChange, single, open, required])

  // only call this, if the menu was closed, since we don't want to trigger an onChange only because the component was mounted
  const previousOpen = usePrevious(open)
  useEffect(() => {
    if (onOpenChange) onOpenChange(open)
    if (previousOpen && !open && !fireInsteadOfSelect) {
      if (single) {
        // FIXME: would something break if we make it null instead of ''?
        if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ single picker closed', selectedKeys[0] || '')
        onChange(selectedKeys[0] || '')
      } else {
        if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ multi picker closed', selectedKeys)
        onChange(selectedKeys)
      }
    }
  }, [previousOpen, open])

  useEffect(() => {
    if (immediateChange && open) {
      if (single) {
        // FIXME: would something break if we make it null instead of ''?
        if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ single picker immediateChange open', selectedKeys[0] || '')
        onChange(selectedKeys[0] || '')
      } else {
        if (debugMode) console.log('🐞 ~ SearchSelect ~ onChange ~ multi picker immediateChange open', selectedKeys)
        onChange(selectedKeys)
      }
    }
  }, [selectedKeys, open, immediateChange])

  const optionItems = useMemo(() => {
    return (filteredOptions || []).map(({ key, label, tags, color, icon, objects_count, assignType }) => {
      const checked = selectedKeys.includes(key)
      return {
        key,
        label,
        tags,
        color,
        icon,
        checked,
        objects_count,
        assignType,
        active: single && checked,
        onClick: (e) => {
          if (!single) e.preventDefault()
          onToggleOption(key, 'optionItems = useMemo')
        }
      }
    })
  }, [filteredOptions, selectedKeys])
  const disallowedSectionsWithItems = useMemo(() => {
    return (filteredDisallowedSections || []).map(section => {
      return {
        ...section,
        menuItems: section.menuItems.map(({ key, label, tags, color, icon, objects_count, assignType }) => {
          const checked = selectedKeys.includes(key)
          return {
            key,
            label,
            tags,
            color,
            icon,
            checked,
            objects_count,
            assignType,
            disabled: true,
            active: single && checked
          }
        })
      }
    })
  }, [filteredDisallowedSections, selectedKeys])

  useEffect(() => {
    if (required && single && !selectedKeys.length && filteredOptions.length) {
      onToggleOption(filteredOptions[0].key, 'useEffect [required, single, selectedKeys, filteredOptions]')
    }
  }, [required, single, selectedKeys, filteredOptions])

  // we have to react to value changes, since the parent component might change the value
  useEffect(() => {
    const newValue = valueToArray(value)
    if (debugMode) console.log('🐞 ~ SearchSelect ~ useEffect ~ value changed', role, newValue, selectedKeys)
    if (!isEqual(newValue, selectedKeys)) {
      setSelectedKeys(newValue)
    }
  }, [value])

  const selectAll = useCallback(() => {
    if (debugMode) {
      console.log('SearchSelect setSelectedKeys', uniq([
        ...selectedKeys,
        ...(filteredOptions || []).map((item) => item.key.toString())
      ]))
    }
    setSelectedKeys(uniq([
      ...selectedKeys,
      ...(filteredOptions || []).map((item) => item.key.toString())
    ]))
  }, [selectedKeys, filteredOptions])

  const onEnter = useCallback(() => {
    if (!(filteredOptions.length === 1 || (tags && filteredTags.length === 1))) return

    let itemToSelect
    if (filteredOptions.length === 1) {
      itemToSelect = filteredOptions[0]
    }

    onToggleOption(itemToSelect.key, 'onEnter')
    if (!single) setTimeout(() => setText(''), 400)
  }, [filteredOptions, tags, filteredTags, onToggleOption, single])

  const textRef = useRef()
  const firstItemRef = useRef()
  const virtuosoRef = useRef()

  const focusFirstItem = useCallback(() => {
    if (firstItemRef.current) {
      firstItemRef.current.focus()
    } else {
      if (virtuosoRef.current) {
        virtuosoRef.current.scrollToIndex(0)
      }
    }
  }, [])

  const onTextChange = useCallback((t) => {
    startTransition(() => {
      setFilterText(t)
    })
  }, [])

  const typesWithBold = ['round', 'templates']
  const selection = useMemo(() => {
    if (selectedKeys.length && menuType === 'tag' && showMultiTags) {
      // TODO: should we have a component like BadgeCollection?
      return selectedKeys.map((key) => {
        const o = (options || []).find((item) => item.key == key)
        return o && <TagBadgeNew key={key} references={o.objects_count} hex={o.color} className='mr-1 my-0.5'>{o.label}</TagBadgeNew>
      })
    }

    if (!selectedKeys.length || (selectedKeys.length > 1 && !optionsLabel)) {
      return <Text bold={typesWithBold.includes(type)} color='gray'>{placeholder}</Text>
    }

    const selectedOption = (options || []).find((item) => item.key == selectedKeys[0])
    const t = (selectedKeys.length === 1 && selectedOption) ? selectedOption.label : `${selectedKeys.length} ${optionsLabel}`

    if (selectedKeys.length === 1 && selectedOption && menuType === 'tag') {
      return <TagBadgeNew references={selectedOption.objects_count} hex={selectedOption.color} assignType={selectedOption.assignType}>{t}</TagBadgeNew>
    }

    return <Text bold={typesWithBold.includes(type)} color={typesWithBold.includes(type) && !disabled ? 'blue' : 'gray'}>{t}</Text>
  }, [
    options,
    placeholder,
    optionsLabel,
    showMultiTags,
    menuType,
    selectedKeys
  ])

  if (loading) {
    return (
      <span
        className={cn(
          s.wrapper,
          s[type],
          s[size],
          s[mode],
          graybg ? s.graybg : null,
          className
        )}
      >
        <LoadingOutlined />
        <span className='w-full overflow-hidden overflow-ellipsis whitespace-nowrap min-w-3' />
        {!disabled && <Icon name='ChevronDown' className={s.arrow} />}
      </span>
    )
  }

  const getMenuSections = () => {
    if (filteredDisallowedSections.length > 0) {
      return [
        {
          key: 'allowed',
          title: intl.formatMessage(globalMessages.qualified),
          menuItems: optionItems
        },
        ...disallowedSectionsWithItems
      ]
    } else if (lastUsed) {
      const lastUsedItems = lastUsed.map(id => find(optionItems, (i) => i.key == id)).filter(i => i)
      return [
        ...(lastUsedItems.length
          ? [{
              key: 'lastUsed',
              title: intl.formatMessage(labelMessages.lastUsed),
              menuItems: lastUsedItems
            }]
          : []),
        {
          key: 'menu',
          title: lastUsedItems.length === 0 ? undefined : intl.formatMessage(labelMessages.all),
          menuItems: optionItems
        }
      ]
    } else {
      return [
        ...(tagItems && tagItems.length
          ? [{
              key: 'tags',
              title: intl.formatMessage(labelMessages.groups),
              type: 'tag',
              menuItems: tagItems
            }]
          : []),
        {
          key: 'menu',
          title: tagItems && tagItems.length ? itemsGroup : undefined,
          menuItems: optionItems
        }
      ]
    }
  }

  const trigger = customTrigger || (
    <a
      className={cn(
        'notranslate',
        s.wrapper,
        s[type],
        s[size],
        s[mode],
        {
          [s.opened]: open,
          [s.hasValue]: !!selectedKeys.length && !disabled,
          [s.graybg]: graybg,
          [s.hasError]: hasError
        },
        className
      )}
      data-role={role}
      disabled={disabled}
      name={name}
      data-name={rest['data-name']}
    >
      {/* <RenderCount /> */}
      {prefixIcon && (
        <div className={cn(s.prefixWrapper, { [s.hasValue]: !!selectedKeys.length && !disabled })}>
          {typeof prefixIcon === 'string' ? <Icon name={prefixIcon} /> : prefixIcon}
        </div>)}
      {selectionAsComponent
        ? (
          <div className={cn(s.selectionWrapper, menuType === 'tag' && showMultiTags && s.asMultiTags)}>
            {selection}
          </div>
          )
        : (
          <span className={cn(s.selectionWrapper, menuType === 'tag' && showMultiTags && s.asMultiTags)}>
            {withTooltip ? <Tooltip title={selection}>{selection}</Tooltip> : selection}
          </span>
          )}
      {!disabled && <Icon name='ChevronDown' className={cn(s.arrow, open && s.rotated)} />}
    </a>
  )
  const tooltipWrapperType = disabled && selectedKeys.length > 1 ? getFilterTypeByRole(role) : null

  return (
    <DropdownMenu
      side={side}
      align={align}
      alignOffset={alignOffset}
      sideOffset={sideOffset}
      header={(
        <Header
          allowClear={allowClear}
          noSearch={noSearch}
          filteredOptions={filteredOptions}
          selectedKeys={selectedKeys}
          single={single}
          text={text}
          textRef={textRef}
          onSelectAll={selectAll}
          onReset={onToggleOption}
          onEnter={onEnter}
          onTab={focusFirstItem}
          onTextChange={onTextChange}
        />
      )}
      menuType={menuType}
      menuSections={getMenuSections()}
      loading={loading}
      checkbox={!single}
      itemsClassName={s.item}
      onVisibleChange={setOpen}
      open={open}
      onPointerMove={(e) => {
        // Prevent dropdown items from stealing the focus from the input field
        if (document.activeElement === textRef.current) {
          e.preventDefault()
        }
      }}
      fitWidth={fitWidth}
      virtuosoRef={virtuosoRef}
      firstItemRef={firstItemRef}
      withPortal={withPortal}
      mobileFull
      modalProps={{
        noPadding: true,
        title: modalTitle || placeholder,
        visible: true,
        onCancel: () => setOpen(false),
        footer: {
          hideCancel: true,
          onPrimary: () => setOpen(false)
        }
      }}
    >
      {tooltipWrapperType
        ? (
          <FilterValueTooltipWrapper side='bottom' type={tooltipWrapperType} value={selectedKeys} wrapTrigger>
            {trigger}
          </FilterValueTooltipWrapper>
          )
        : trigger}
    </DropdownMenu>
  )
}
