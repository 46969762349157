import s from './OperatorPanel.module.scss'
import { useMemo, useState } from 'react'
import { Button } from 'components/Button'
import classNames from 'classnames'
import CustomerSelect from 'components/CustomerSelect'
import DemoSwitcher from 'components/DemoSwitcher'
import { useGeneralStore } from 'hooks/store'
import { useRole, useSelectedCustomer, useUser } from 'hooks'
import { OPERATOR } from 'constants/index'
import { useLocation } from 'react-router-dom'
import { some } from 'lodash'

const techUsers = ['operator', 'adrian.spener', 'alexander', 'arseny', 'ashwin', 'erfan', 'fred', 'javier', 'tapesh']

// check if the mail of the user starts with a mail of a tech user
const isTechUser = (userEmail) => userEmail && some(techUsers, (techUser) => userEmail.startsWith(techUser))

export const OperatorPanel = () => {
  const [isOpen, setIsOpen] = useState(false)
  const customers = useGeneralStore((state) => state.allCustomers)
  const { pathname } = useLocation()
  const customer = useSelectedCustomer()
  const { role } = useRole()
  const { user } = useUser()

  const userIsTech = useMemo(() => isTechUser(user?.email), [user])

  const customerName = useMemo(() => {
    if (!customer || !customers) return null
    return customers.find((c) => c.id == customer)?.name
  }, [customer, customers])

  if (role !== OPERATOR || (!userIsTech && !pathname.startsWith('/settings'))) return null

  return (
    <div className={classNames(s.root, isOpen ? s.open : null)}>
      <div className={s.content}>
        <CustomerSelect className='grow' />
        <DemoSwitcher />
      </div>
      <div className={s.trigger}>
        <Button className='text-ellipsis overflow-hidden whitespace-nowrap w-full justify-between flex-row-reverse' size='xs' type='pure' icon={isOpen ? 'ChevronUp' : 'ChevronDown'} onClick={() => setIsOpen(!isOpen)}>
          {customerName}
        </Button>
      </div>
    </div>
  )
}
