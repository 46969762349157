import {
  CUSTOM_RANGE,
  DEFAULT_LOCALE,
  DFNS_WEEK_FORMAT,
  LOCALES,
  OPERATOR
} from 'constants/index'
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  formatISO,
  getISOWeek,
  getTime,
  parse,
  parseISO,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  isEqual as isEqualDfns
} from 'date-fns'
import {
  defaultTo,
  head,
  last,
  map,
  pipe,
  prop,
  sortBy,
  toPairs
} from 'ramda'
import { find, findIndex, flatten, forEach, isEqual, pick, pickBy, startCase, set, map as ldMap, orderBy, merge, chain, get, defaultTo as ldDefaultTo, filter, zipObject, flatMap } from 'lodash'

import { formatLocalized, getDateTimeAxis, getDateTimeAxisNew } from './datetime'
import globalMessages from 'components/globalMessages'
/* eslint-disable camelcase */
import message from 'services/message'
import rangePickerMessages from 'components/RangePicker/messages'
import seedrandom from 'seedrandom'

export function noop () { }

export const getFormErrors = ({ values, errors }) => {
  if (errors.non_field_errors) {
    // error on alert
    return { error: errors.non_field_errors && errors.non_field_errors[0] }
  } else {
    // errors on form fields
    const formErrors = {}
    const fields = Object.keys(errors)
    fields.forEach((field) => {
      formErrors[field] = {
        value: values[field],
        errors: [
          {
            field,
            message: errors[field][0]
          }
        ]
      }
    })

    const messages = flatten(Object.values(errors))
    return { formErrors, messages }
  }
}

export const getOrdering = (sorter = {}, replaceDict = {}) =>
  sorter.field
    ? `${sorter.order === 'descend' ? '-' : ''}${replaceDict[sorter.field] ? replaceDict[sorter.field] : sorter.field}`
    : undefined

export const getMecoName = (item) =>
  [item.menuline_name, item.component_name].filter((item) => item).join(' ⚭ ')

export const filterOption = (input, option) => {
  if (option == null || typeof (option) === 'boolean') return false

  const loweredInput = input.toLowerCase()
  const filterText = (text) => text.toLowerCase().includes(loweredInput) || // search for the whole word
    text.split(' ').map(w => w[0]).join('').toLowerCase().includes(loweredInput) // search for the first letters of the words

  if (typeof (option) === 'string') { return filterText(option) }
  if (option.props.text) { return filterText(option.props.text) }
  if (option.props.primarySearchValue) {
    return filterText(option.props.primarySearchValue) || (option.props.secondarySearchValue && filterText(option.props.secondarySearchValue))
  }

  if (typeof (option.props.children) === 'string') { return filterText(option.props.children) }
  if (Array.isArray(option.props.children)) { return option.props.children.some((child) => filterOption(input, child)) }
  if (option.props.children && typeof (option.props.children) === 'object' && option.props.children.props && typeof (option.props.children.props.children) === 'string') { return filterText(option.props.children.props.children) }
  return true
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const getUserName = ({ first_name, last_name, email }) =>
  first_name || last_name
    ? [first_name, last_name].filter((item) => item).join(' ')
    : email

export const getRangePreset = (key, isoFormat) => {
  // FIXME: While calculations are now made with date-fns, we still return moment objects for compatibility. Should be removed at some point
  const rangePresets = {
    today: {
      value: [
        startOfDay(new Date()),
        endOfDay(new Date())
      ],
      label: globalMessages.today
    },
    yesterday: {
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1))
      ],
      label: globalMessages.yesterday
    },
    tomorrow: {
      value: [
        startOfDay(addDays(new Date(), 1)),
        endOfDay(addDays(new Date(), 1))
      ],
      label: globalMessages.tomorrow
    },
    tomorrowPlus1: {
      value: [
        startOfDay(addDays(new Date(), 2)),
        endOfDay(addDays(new Date(), 2))
      ],
      label: globalMessages.dayAfterTomorrow
    },
    thisWeek: {
      value: [
        startOfISOWeek(new Date()),
        endOfISOWeek(new Date())
      ],
      label: rangePickerMessages.thisWeek
    },
    weekToDate: {
      value: [
        startOfISOWeek(new Date()),
        endOfDay(new Date())
      ],
      label: rangePickerMessages.weekToDate
    },
    lastWeek: {
      value: [
        startOfISOWeek(addWeeks(new Date(), -1)),
        endOfISOWeek(addWeeks(new Date(), -1))
      ],
      label: rangePickerMessages.lastWeek
    },
    nextWeek: {
      value: [
        startOfISOWeek(addWeeks(new Date(), 1)),
        endOfISOWeek(addWeeks(new Date(), 1))
      ],
      label: rangePickerMessages.nextWeek
    },
    next2Week: {
      value: [
        startOfISOWeek(addWeeks(new Date(), 2)),
        endOfISOWeek(addWeeks(new Date(), 2))
      ],
      label: rangePickerMessages.weekNumber,
      params: {
        weekNumber: getISOWeek(addWeeks(new Date(), 2))
      }
    },
    next3Week: {
      value: [
        startOfISOWeek(addWeeks(new Date(), 3)),
        endOfISOWeek(addWeeks(new Date(), 3))
      ],
      label: rangePickerMessages.weekNumber,
      params: {
        weekNumber: getISOWeek(addWeeks(new Date(), 3))
      }
    },
    next4Week: {
      value: [
        startOfISOWeek(addWeeks(new Date(), 4)),
        endOfISOWeek(addWeeks(new Date(), 4))
      ],
      label: rangePickerMessages.weekNumber,
      params: {
        weekNumber: getISOWeek(addWeeks(new Date(), 4))
      }
    },
    lastTwoWeeks: {
      value: [
        startOfISOWeek(addWeeks(new Date(), -2)),
        endOfISOWeek(addWeeks(new Date(), -1))
      ],
      label: rangePickerMessages.lastTwoWeeks,
      params: {
        weekNumber: getISOWeek(addWeeks(new Date(), 3))
      }
    },
    thisMonth: {
      value: [
        startOfMonth(new Date()),
        endOfMonth(new Date())
      ],
      label: rangePickerMessages.thisMonth
    },
    monthToDate: {
      value: [
        startOfMonth(new Date()),
        endOfDay(new Date())
      ],
      label: rangePickerMessages.monthToDate
    },
    lastMonth: {
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1))
      ],
      label: rangePickerMessages.lastMonth
    },
    nextMonth: {
      value: [
        startOfMonth(addMonths(new Date(), 1)),
        endOfMonth(addMonths(new Date(), 1))
      ],
      label: rangePickerMessages.nextMonth
    },
    thisQuarter: {
      value: [
        startOfQuarter(new Date()),
        endOfQuarter(new Date())
      ],
      label: rangePickerMessages.thisQuarter
    },
    quarterToDate: {
      value: [
        startOfQuarter(new Date()),
        endOfDay(new Date())
      ],
      label: rangePickerMessages.quarterToDate
    },
    lastQuarter: {
      value: [
        startOfQuarter(addQuarters(new Date(), -1)),
        endOfQuarter(addQuarters(new Date(), -1))
      ],
      label: rangePickerMessages.lastQuarter
    },
    nextQuarter: {
      value: [
        startOfQuarter(addQuarters(new Date(), 1)),
        endOfQuarter(addQuarters(new Date(), 1))
      ],
      label: rangePickerMessages.nextQuarter
    },
    thisYear: {
      value: [
        startOfYear(new Date()),
        endOfYear(new Date())
      ],
      label: rangePickerMessages.thisYear
    },
    yearToDate: {
      value: [
        startOfYear(new Date()),
        endOfDay(new Date())
      ],
      label: rangePickerMessages.yearToDate
    },
    lastYear: {
      value: [
        startOfYear(addYears(new Date(), -1)),
        endOfYear(addYears(new Date(), -1))
      ],
      label: rangePickerMessages.lastYear
    },
    [CUSTOM_RANGE]: {
      value: [],
      label: rangePickerMessages.customRange
    }
  }

  const preset = rangePresets[key]
  if (isoFormat) {
    return [
      formatISO(preset.value[0], { representation: 'date' }),
      formatISO(preset.value[1], { representation: 'date' })
    ]
  }

  return preset
}

/** Legacy Fallback for crashing Dashboard 1.0 gram per sale chart */
export const getSimpleChartDataLegacy = (data, groupBy = 'date', range, weekdays, filterOutNulls, filterOutZeros) => {
  const dataKeys = Object.keys(data)

  // if groups have no sold items or value, it won't be there
  // we want to respect gaps with zero values, so let's first build the time axis based on the range and interval
  const xAxisPoints = getDateTimeAxis(groupBy, range, weekdays)
  const axisKeys = Object.keys(xAxisPoints)

  // Now let's nerge our actual data with the xAxis and warn, if we have group we don't have in our axis
  let valuesAreNull = true
  let timestampWarn = false
  dataKeys.forEach(k => {
    if (k === 'null' && axisKeys.length === 1) {
      xAxisPoints[axisKeys[0]] = data[k]
      valuesAreNull = false
    } else if (k === 'null') {
      // we have some data which do not have a timestamp. Let's ignore them and warn about it
      valuesAreNull = false
      if (data[k] > 0) {
        timestampWarn = true
      }
    } else if (xAxisPoints[k] != null) {
      xAxisPoints[k] = data[k]
      if (data[k] !== null) { valuesAreNull = false }
    } else { console.warn(`Data group ${k} is not included in the xAxisPoints and will be ignored!`, xAxisPoints) }
  })

  let arr = pipe(
    defaultTo({}),
    toPairs,
    map(([name, value]) => {
      const byHour = name.includes('T')
      const byWeek = name.includes('W')

      const date = getTime(byHour || !byWeek ? parseISO(name) : parse(name, DFNS_WEEK_FORMAT, new Date()))

      return {
        date,
        value
      }
    }),
    sortBy(prop('date'))
  )(xAxisPoints)

  if (filterOutNulls) {
    arr = arr.filter(i => i.value != null)
  }
  if (filterOutZeros) {
    arr = arr.filter(i => i.value != 0)
  }

  // if all data values are null, we can't get this data technically
  // and it doesn't make sense to render a divided line or something
  if (valuesAreNull) {
    return {
      data: [],
      valuesAreNull: true
    }
  }

  return {
    data: arr,
    hasDataWithoutTimestamp: timestampWarn
  }
}

export const getSimpleChartData = (data, groupBy = 'date', range, hasExactDaysInRange, filterOutNulls, filterOutZeros, nullsToZeros) => {
  const dataKeys = Object.keys(data)

  // if groups have no sold items or value, it won't be there
  // we want to respect gaps with zero values, so let's first build the time axis based on the range and interval
  const xAxisPoints = getDateTimeAxisNew(groupBy, range, hasExactDaysInRange, true)
  const axisKeys = Object.keys(xAxisPoints)

  // Now let's nerge our actual data with the xAxis and warn, if we have group we don't have in our axis
  let valuesAreNull = true
  let timestampWarn = false
  dataKeys.forEach(k => {
    if (k === 'null' && axisKeys.length === 1) {
      xAxisPoints[axisKeys[0]] = data[k]
      valuesAreNull = false
    } else if (k === 'null') {
      // we have some data which do not have a timestamp. Let's ignore them and warn about it
      valuesAreNull = false
      if (data[k] > 0) {
        timestampWarn = true
      }
    } else if (xAxisPoints[k] !== undefined) {
      xAxisPoints[k] = data[k]
      if (data[k] != null) { valuesAreNull = false }
    } else { console.warn(`Data group ${k} is not included in the xAxisPoints and will be ignored!`, xAxisPoints) }
  })

  let arr = pipe(
    defaultTo({}),
    toPairs,
    map(([name, value]) => {
      const byHour = name.includes('T')
      const byWeek = name.includes('W')

      const date = getTime(byHour || !byWeek ? parseISO(name) : parse(name, DFNS_WEEK_FORMAT, new Date()))

      return {
        date,
        value
      }
    }),
    sortBy(prop('date'))
  )(xAxisPoints)

  if (filterOutNulls) {
    arr = arr.filter(i => i.value != null)
  }
  if (filterOutZeros) {
    arr = arr.filter(i => i.value != 0)
  }
  if (nullsToZeros) {
    arr = arr.map(i => ({ ...i, value: i.value == null ? 0 : i.value }))
  }

  // if all data values are null, we can't get this data technically
  // and it doesn't make sense to render a divided line or something
  if (valuesAreNull) {
    return {
      data: [],
      valuesAreNull: true
    }
  }

  return {
    data: arr,
    hasDataWithoutTimestamp: timestampWarn,
    hasOnlyZeros: arr.every(i => i.value === 0)
  }
}

export const getDomain = (data) => [prop('date', head(data)), prop('date', last(data))]

export const toCurrency = (value, withSymbol = true) => {
  let val = typeof value === 'string' ? parseFloat(value) : value
  if (!val) val = 0

  val = Math.round((value + Number.EPSILON) * 100) / 100

  return withSymbol
    ? `${val.toFixed(2).replace('.', ',')} €`
    : val.toFixed(2).replace('.', ',')
}

export const findAndReplaceField = (params, fieldName, replacedFieldName) => {
  const newParams = [...params]
  const idx = findIndex(newParams, { field: fieldName })
  if (idx !== -1) {
    newParams[idx] = {
      ...newParams[idx],
      field: replacedFieldName
    }
  }
  return newParams
}

/**
 * Modifies a set of non-redux generated filter parameters for data queries which are not on item level
 * @param {any[]} params arry of params
 */
export const modifyParamsForRelation = (params) => {
  if (!params) { return params }
  let p = [...params]
  p = findAndReplaceField(p, 'sales_location', 'item_offering__sales_location')
  p = findAndReplaceField(p, 'item__name', 'item_offering__item__name')
  p = findAndReplaceField(p, 'item__category_1', 'item_offering__item__category_1')
  p = findAndReplaceField(p, 'item__category_2', 'item_offering__item__category_2')
  p = findAndReplaceField(p, 'component', 'item_offering__component')
  p = findAndReplaceField(p, 'menuline', 'item_offering__menuline')
  return p
}

export const modifyFieldForSalesSet = (groupBy, value) => {
  if (groupBy !== 'hour') { return value }

  switch (value) {
    case 'num_sold':
      return 'amount'
    case 'sales_location':
    case 'item__name':
    case 'component':
    case 'menuline':
    case 'item__category_1':
    case 'item__category_2':
    case 'sales_location__name':
      return `item_offering__${value}`
    default:
      return value
  }
}

/**
 * Modifies a set of non-redux generated filter parameters for the ToDo data-query
 * @param {any[]} params array of params
 */
export const modifyParamsForTodo = (params) => {
  if (!params) { return params }
  let p = [...params]
  p = findAndReplaceField(p, 'sales_location', 'location')
  p = findAndReplaceField(p, 'sales_location__name', 'location__name')
  p = findAndReplaceField(p, 'name', 'item__name')
  p = findAndReplaceField(p, 'date', 'due_by')
  p = p.filter((i) => !['menuline', 'component'].includes(i.field))
  return p
}

export const modifyDateRangeToISORange = (params) => {
  if (!params) { return params }
  const newParams = [...params]
  const idx = findIndex(params, { field: 'date', operator: 'range' })
  if (idx !== -1) {
    newParams[idx] = {
      ...newParams[idx],
      value: [
        startOfDay(parseISO(newParams[idx].value[0])).toISOString(),
        endOfDay(parseISO(newParams[idx].value[1])).toISOString()
      ]
    }
  }
  return newParams
}

// TODO: rename when removing old dashboard
export const formatChartLabelNew = (date, groupBy, intl, withWeekday = true) => {
  if (date === 'null') {
    return intl.formatMessage(globalMessages.unknownTime)
  }
  try {
    switch (groupBy) {
      case 'hour':
        return format(typeof (date) === 'number' ? new Date(date) : parseISO(date), 'HH:mm')
      case 'date':
        return formatLocalized(new Date(date), withWeekday ? 'EEEEEE, P' : 'P', intl)
      case 'week':
        if (typeof date === 'string' && !date.includes('W')) {
          // FIXME: this is known to happen when changing from hour or date to week, the tooltip in BarChart is rerendered with a new groupBy but date from the chartData hasn't changed
          console.warn('Date format and groupBy mismatch', { date, groupBy })
          return ''
        }
        return format(typeof (date) === 'number' ? new Date(date) : parse(date, DFNS_WEEK_FORMAT, new Date()), `'${intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
    }
  } catch (ex) {
    console.error(`Error formatting ${groupBy} chart label.`, date, withWeekday)
    console.error(ex)
    return 'Fehler'
  }
}

export const formatChartLabel = (date, groupBy, intl, source = 'unknown', withWeekday = true) => {
  if (date === 'null') {
    return intl.formatMessage(globalMessages.unknownTime)
  }
  try {
    switch (groupBy) {
      case 'hour':
        return typeof (date) === 'number'
          ? format(new Date(date), 'HH:mm')
          : format(parseISO(date), 'HH:mm')
      case 'date':
        return typeof (date) === 'number'
          ? formatLocalized(new Date(date), withWeekday ? 'EEEEEE, P' : 'P', intl)
          : formatLocalized(new Date(date), withWeekday ? 'EEEEEE, P' : 'P', intl)
      case 'week':
        try {
          return typeof (date) === 'number'
            ? format(new Date(date), `'${intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
            : format(parse(date, DFNS_WEEK_FORMAT, new Date()), `'${intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
        } catch (ex) {
          return typeof (date) === 'number'
            ? format(new Date(date), `'${intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
            : format(parseISO(date), `'${intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
        }
    }
  } catch (ex) {
    console.error(`Error formatting ${groupBy} chart label from ${source}.`, date)
    console.error(ex)
    return 'Fehler'
  }
}

export const toPercent = (decimal, absolute = false, fixed = 0) => `${(absolute ? decimal : decimal * 100).toFixed(fixed)}%`
export const pickDefined = (obj) => pickBy(obj, (value) => value != null)

export const guessName = (user) => {
  if (user.first_name && !user.last_name) {
    return user.first_name
  } else if (!user.first_name && user.last_name) {
    return user.last_name
  } else if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`
  } else {
    return startCase(user.email.split('@')[0])
  }
}

export const getAccessCollection = (user, intl) => [
  ...(user.safety_setting_rights ? [intl ? intl.formatMessage(globalMessages.accessSafetySettings) : 'safety_setting_rights'] : []),
  ...(user.food_waste_access ? [intl ? intl.formatMessage(globalMessages.accessFoodwaste) : 'food_waste_access'] : []),
  ...(user.offering_view_access ? [intl ? intl.formatMessage(globalMessages.accessOfferingView) : 'offering_view_access'] : [])
]

/**
 * Truncate a list of items and adds an appendix (e.g. to prevent large tooltips)
 * @param {the array of strings} items
 */
export const truncateItems = (items) => {
  const truncateAfter = items.length > 40 ? 25 : 35
  let texts
  let truncated = false
  if (items.length > truncateAfter) {
    texts = [...items].splice(0, truncateAfter)
    truncated = true
  } else {
    texts = items
  }
  return {
    items: texts,
    truncated,
    leftOver: truncated ? items.length - texts.length : 0
  }
}

export const replaceProductionInGridData = (data, production) => {
  const clone = { ...data }
  try {
    forEach(data.data, (aValue, aKey) => {
      forEach(aValue, (bValue, bKey) => {
        clone.data[aKey][bKey] = bValue.map(p => p.id === production.id
          ? ({
              ...p,
              ...pick(production, ['forecast_limit', 'num_planned', 'num_sold', 'num_produced'])
            })
          : p)
      })
    })
  } catch (ex) {
    console.error(ex)
  }

  return clone
}

export const replaceProductionInTableData = (data, production) => {
  const updatedResults = data.results.map(p => p.id === production.id
    ? ({
        ...p,
        ...pick(production, ['forecast_limit', 'num_planned', 'num_sold', 'num_produced'])
      })
    : p)
  return {
    ...data,
    results: updatedResults
  }
}

export const normalizeLocations = (locations) => locations.map(l => (typeof l === 'string') ? parseInt(l, 10) : l)

export const handleMutationError = (errors, reject) => {
  if (errors.detail) {
    message.error(errors.detail)
    reject(errors.detail)
    return
  }

  if (errors.error && errors.error.message) {
    message.error(errors.error.message)
    reject(errors.error)
    return
  }

  if (typeof (errors) === 'string') {
    message.error(errors)
    reject(new Error(errors))
    return
  }

  const { error, messages } = getFormErrors({ errors, values: {} })
  if (error) {
    message.error(error)
    reject(error)
    return
  }
  if (messages) {
    if (typeof (messages) === 'string') {
      message.error(messages)
    } else {
      message.error(JSON.stringify(messages))
    }

    reject(messages)
  }
}

const rndGen = seedrandom()
export const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)]
export const random = (min, max) => Math.floor(rndGen() * (max - min + 1)) + min

export const pickTextColorBasedOnBgColor = (bgColor, lightColor = '#FFFFFF', darkColor = '#000000') => {
  const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
    ? darkColor
    : lightColor
}

export const isEmpty = (input) => {
  if (typeof input === 'undefined' || input == null) return true
  if (typeof input !== 'string') return false

  return input.replace(/\s+/g, '').length === 0
}

export const getPatchValues = (initValues, formValues, fields) => {
  const diff = {}
  fields.forEach(field => {
    if (!initValues[field] || ['string', 'number', 'boolean'].includes(typeof initValues[field])) {
      if (initValues[field] === undefined) console.warn(`Field ${field} in initValues is undefined`)
      if (formValues[field] !== initValues[field]) {
        diff[field] = formValues[field]
      }
    } else if (Array.isArray(initValues[field])) {
      let cmpValues, cmpFormValues
      if (initValues[field][0] && typeof (initValues[field][0]) === 'object') {
        // if the object has a prop id, we pick and sort by it, otherwise we take the first attribute containing _id
        const keys = Object.keys(initValues[field][0])
        const idKey = keys.includes('id') ? 'id' : keys.find(k => k.endsWith('_id'))
        cmpValues = [...initValues[field].map(i => i[idKey]).sort()]
      } else {
        cmpValues = [...initValues[field].sort()]
      }
      if (formValues[field][0] && typeof (formValues[field][0]) === 'object') {
        // if the object has a prop id, we pick and sort by it, otherwise we take the first attribute containing _id
        const keys = Object.keys(formValues[field][0])
        const idKey = keys.includes('id') ? 'id' : keys.find(k => k.endsWith('_id'))
        cmpFormValues = [...formValues[field].map(i => i[idKey]).sort()]
      } else {
        cmpFormValues = [...formValues[field].sort()]
      }

      if (!isEqual(cmpFormValues, cmpValues)) {
        diff[field] = formValues[field]
      }
    } else if (typeof initValues[field] === 'object' && initValues[field]._isAMomentObject) {
      const d2 = formValues[field] ? formValues[field]._d : null
      if (!isEqualDfns(initValues[field]._d, d2)) {
        diff[field] = formValues[field]
      }
    } else if (typeof initValues[field] === 'object' && initValues[field].option && initValues[field].value) {
      if (initValues[field].option !== formValues[field].option || !isEqual(initValues[field].value, formValues[field].value)) {
        diff[field] = { ...formValues[field] }
      }
    } else if (typeof initValues[field] === 'object') {
      if (!isEqual(initValues[field], formValues[field])) {
        diff[field] = formValues[field]
      }
    } else {
      console.error(`Error on ${field}: Only string, number, moment, array, object and dateRage values are supported right now.`, initValues[field], typeof initValues[field], formValues[field], typeof formValues[field])
      throw new Error(`Error on ${field}: Only string, number, moment, array, object and dateRage values are supported right now.`)
    }
  })
  return diff
}

// get a numeric hash from a string, see https://stackoverflow.com/a/52171480
export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export const copyToClipboard = (str, type, intl) => {
  if (!navigator.clipboard || !navigator.clipboard.writeText) {
    message.error(intl.formatMessage({ id: 'error.missingClipboardAPI', defaultMessage: 'Your browser does not support the Clipboard API. Please ugrade your software.' }))
  }

  navigator.clipboard.writeText(str)
    .then(() => {
      switch (type) {
        default:
          message.success(intl.formatMessage({ id: 'notify.clipboardSuccess', defaultMessage: 'The value was copied to the clipboard.' }))
          break
        case 'link':
          message.success(intl.formatMessage({ id: 'notify.clipboardLinkSuccess', defaultMessage: 'The link was copied to the clipboard.' }))
          break
        case 'password':
          message.success(intl.formatMessage({ id: 'notify.clipboardPasswordSuccess', defaultMessage: 'The password was copied to the clipboard.' }))
          break
      }
    })
    .catch(() => message.error(intl.formatMessage({ id: 'error.clipboardPermission', defaultMessage: 'Transfer to clipboard failed. Please check the browser permissions.' })))
}

export const stripHomeDirectory = (path) => {
  if (isEmpty(path)) return ''
  const strippedPath = path.split('/').splice(3)
  return `/${strippedPath.join('/')}`
}

export const shouldFilterWeekdays = (weekdayFilter) => (weekdayFilter && weekdayFilter.length !== 0 && weekdayFilter.length !== 7)

export const isNullOrEmptyArray = (x) => {
  if (x == null) return true
  if (Array.isArray(x) && x.length === 0) return true
  return false
}

export const sumKeyValue = (obj, key, value) => {
  if (obj[key] != null) {
    obj[key] = obj[key] + value
  } else {
    obj[key] = value
  }
}

export const keyValueObjectToSortedArr = (obj) => {
  const arr = orderBy(ldMap(obj, (value, key) => ({
    key, value
  })), ['value'], ['desc'])
  return arr
}

export const divide100 = (value) => value != null
  ? parseFloat((value / 100).toFixed(4))
  : null

export const multiply100 = (value) => value != null
  ? parseFloat((value * 100).toFixed(4))
  : null

export const getNavigatorsDefaultLocale = () => {
  const navigatorLanguage = !isEmpty(navigator.language) ? navigator.language.substring(0, 2) : DEFAULT_LOCALE.substring(0, 2)
  return find(LOCALES, (locale) => locale.substring(0, 2) === navigatorLanguage) || DEFAULT_LOCALE
}

export const padZero = (value) => String(value).padStart(2, '0')

export const determineCustomerState = (customer, user, allCustomers) => {
  if (!user) return false

  if (user.role === OPERATOR) {
    if (!allCustomers) return false

    const selectedCustomer = find(allCustomers, { id: parseInt(customer) })
    return selectedCustomer
      ? {
          customerType: selectedCustomer.type,
          customerFoodWasteMode: selectedCustomer.food_waste_mode,
          customerSettings: selectedCustomer.settings
        }
      : null
  }

  return {
    customerType: user.customer_type,
    customerFoodWasteMode: user.customer_food_waste_mode,
    customerSettings: user.customer_settings
  }
}

export const getOfferingDates = (data) =>
  chain(data)
    .keys()
    .reduce((acc, meco) => merge(acc, data[meco]), {})
    .keys()
    .sort((a, b) => a.valueOf() - b.valueOf())
    .value()
    .sort()

export const getOfferingWeathers = (data) =>
  chain(data)
    .keys()
    .reduce(
      (acc, meco) => ({
        ...acc,
        ...data[meco]
      }),
      {}
    )
    .mapValues((productions) => ({
      code: productions[0].weather_code,
      description: productions[0].weather_description
    }))
    .value()

export const getOfferingGroupNames = (data, key) =>
  chain(data)
    .keys()
    .reduce((acc, meco) =>
      chain(data[meco])
        .keys()
        .reduce((acc, date) =>
          set(acc, [meco], data[meco][date][0][key])
        , acc)
        .value()
    , {})
    .value()

export const getByPath = (obj, path, defaultValue) => defaultTo(defaultValue, get(obj, path))

export const getConfigTitle = (intl, category, tableType) => {
  if (category === 'order') {
    switch (tableType) {
      default:
        return intl.formatMessage({ id: 'OrderConfigTable.quantileConfigTitle', defaultMessage: 'Quantity Planning' })
      case 'fulfillmentDelay':
        return intl.formatMessage({ id: 'OrderConfigTable.fulfillmentDelayConfigTitle', defaultMessage: 'Fulfillment Delay' })
      case 'orderFactor':
        return intl.formatMessage({ id: 'OrderConfigTable.orderFactorConfigTitle', defaultMessage: 'Order Factor' })
      case 'ordersEnabled':
        return intl.formatMessage({ id: 'OrderConfigTable.ordersEnabledConfigTitle', defaultMessage: 'Orders Enabled' })
      case 'referenceItem':
        return intl.formatMessage({ id: 'OrderConfigTable.referenceItemConfigTitle', defaultMessage: 'Reference Item' })
      case 'batchRounding':
        return intl.formatMessage({ id: 'OrderConfigTable.batchRoundingConfigTitle', defaultMessage: 'Rounding of Ordered Amounts' })
      case 'multidayTracking':
        return intl.formatMessage({ id: 'OrderConfigTable.multidayTrackingConfigTitle', defaultMessage: 'Multi-Day Items' })
    }
  } else if (category === 'todo') {
    switch (tableType) {
      case 'totalQuantile':
      default:
        return intl.formatMessage({ id: 'TodoConfigTable.totalQuantileConfigTitle', defaultMessage: 'Daily Quantile' })
      case 'initialTodos':
        return intl.formatMessage({ id: 'TodoConfigTable.initialTodosConfigTitle', defaultMessage: 'Scheduled Todos' })
      case 'scheduling':
        return intl.formatMessage({ id: 'TodoConfigTable.schedulingConfigTitle', defaultMessage: 'Frequency' })
      case 'inventoryGroup':
        return intl.formatMessage({ id: 'TodoConfigTable.inventoryGroupConfigTitle', defaultMessage: 'Inventory Groups' })
    }
  }
}

export const getConfigDialogTitle = (intl, category, tableType, isEditing) => {
  const title = getConfigTitle(intl, category, tableType)
  if (isEditing) {
    return intl.formatMessage({ id: 'ConfigTable.editConfigTitle', defaultMessage: 'Edit rule »{title}«' }, { title })
  }
  return intl.formatMessage({ id: 'ConfigTable.addConfigTitle', defaultMessage: 'Add new rule »{title}«' }, { title })
}

export const isTouchDevice = () => 'ontouchstart' in window

export const getNameWithParantheses = (type, item, customerSettings) => {
  switch (type) {
    case 'location':
    case 'locations':
      return customerSettings?.showLocationRemotePK
        ? !isEmpty(item.sales_loc) && item.sales_loc !== item.name ? `${item.name} (${item.sales_loc})` : item.name
        : item.name
    case 'item':
    case 'items':
      return !isEmpty(item.remote_pk) && item.remote_pk !== item.name ? `${item.name} (${item.remote_pk})` : item.name
    default:
      return item.name
  }
}

export const getNameWithParanthesesByTypeProps = (type, name, remotePk, customerSettings) => {
  switch (type) {
    case 'location':
    case 'locations':
      return customerSettings?.showLocationRemotePK
        ? !isEmpty(remotePk) && remotePk !== 'null' && remotePk !== name ? `${name} (${remotePk})` : name
        : name
    case 'item':
    case 'items':
      return !isEmpty(remotePk) && remotePk !== 'null' && remotePk !== name ? `${name} (${remotePk})` : name
    default:
      return name
  }
}

export const getNameWithParanthesesByProps = (name, remotePk, customerSettings) => {
  return customerSettings?.showLocationRemotePK
    ? !isEmpty(remotePk) && remotePk !== 'null' && remotePk !== name ? `${name} (${remotePk})` : name
    : name
}

export const groupOrderConfigs = (items) => {
  const grouped = {
    quantile: filter(items, (i) => i.fulfillment_delay == null && i.order_factor == null && i.orders_enabled == null && i.multiday_tracking == null && i.reference_item == null && i.batch_rounding_cutoff == null),
    fulfillmentDelay: filter(items, (i) => i.fulfillment_delay != null),
    orderFactor: filter(items, (i) => i.order_factor != null),
    ordersEnabled: filter(items, (i) => i.orders_enabled != null),
    referenceItem: filter(items, (i) => i.reference_item != null),
    batchRounding: filter(items, (i) => i.batch_rounding_cutoff != null || i.min_amount_rounding_cutoff != null),
    multidayTracking: filter(items, (i) => i.multiday_tracking != null)
  }
  // Count the number of items in each group and compare it with data.items.length to show a warning if there are items that don't belong to any group
  const groupedCount = Object.values(grouped).reduce((acc, curr) => acc + curr.length, 0)
  if (groupedCount !== items.length) {
    console.warn('Some rules do not belong to any group or multiple groups conatain the same items', items, grouped)
  }
  return grouped
}

export const groupTodoConfigs = (items) => {
  const grouped = {
    totalQuantile: filter(items, (i) => i.daily_quantile != null),
    initialTodos: filter(items, (i) => i.scheduled_due_by != null || i.scheduled_last_until != null),
    scheduling: filter(items, (i) => i.lead_quantile != null || i.lead_interval != null || i.sustain_quantile != null || i.sustain_interval != null),
    inventoryGroup: filter(items, (i) => i.inventory_group_enabled != null)
  }
  // Count the number of items in each group and compare it with data.items.length to show a warning if there are items that don't belong to any group
  const groupedCount = Object.values(grouped).reduce((acc, curr) => acc + curr.length, 0)
  if (groupedCount !== items.length) {
    console.warn('Some rules do not belong to any group or multiple groups conatain the same items', items, grouped)
  }
  return grouped
}

export const valueToArray = (value) => !isEmpty(value) ? Array.isArray(value) ? value : [value] : []

const base64ToBytes = (base64) => {
  const binString = window.atob(base64)
  return Uint8Array.from(binString, (m) => m.codePointAt(0))
}

const bytesToBase64 = (bytes) => {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte)
  ).join('')
  return window.btoa(binString)
}

export const unicodeToBase64 = (str) => bytesToBase64(new TextEncoder().encode(str))
export const base64ToUnicode = (base64) => new TextDecoder().decode(base64ToBytes(base64))

/**
 * Combine multiple Uint8Arrays into one.
 *
 * @param {ReadonlyArray<Uint8Array>} uint8arrays
 * @returns {Promise<Uint8Array>}
 */
const concatUint8Arrays = async (uint8arrays) => {
  const blob = new Blob(uint8arrays)
  const buffer = await blob.arrayBuffer()
  return new Uint8Array(buffer)
}

/**
 * Convert a string to its UTF-8 bytes and compress it.
 *
 * @param {string} str
 * @returns {Promise<Uint8Array>}
 */
export const compress = async (str) => {
  // Convert the string to a byte stream.
  const stream = new Blob([str]).stream()

  // Create a compressed stream.
  const compressedStream = stream.pipeThrough(new CompressionStream('gzip'))

  // Read all the bytes from this stream.
  const reader = compressedStream.getReader()
  const chunks = []
  while (true) {
    const { value, done } = await reader.read()
    if (done) break
    chunks.push(value)
  }
  return await concatUint8Arrays(chunks)
}

/**
 * Decompress bytes into a UTF-8 string.
 *
 * @param {Uint8Array} compressedBytes
 * @returns {Promise<string>}
 */
export const decompress = async (compressedBytes) => {
  // Convert the bytes to a stream.
  const stream = new Blob([compressedBytes]).stream()

  // Create a decompressed stream.
  const decompressedStream = stream.pipeThrough(new DecompressionStream('gzip'))

  // Read all the bytes from this stream.
  const reader = decompressedStream.getReader()
  const chunks = []
  while (true) {
    const { value, done } = await reader.read()
    if (done) break
    chunks.push(value)
  }
  const stringBytes = await concatUint8Arrays(chunks)

  // Convert the bytes to a string.
  return new TextDecoder().decode(stringBytes)
}

// Function to convert Uint8Array to Base64 string
export const uint8ArrayToBase64 = (uint8Array) => {
  let binary = ''
  uint8Array.forEach(byte => {
    binary += String.fromCharCode(byte)
  })
  return window.btoa(binary)
}

// Function to convert Base64 string to Uint8Array
export const base64ToUint8Array = (base64String) => {
  const binaryString = window.atob(base64String)
  const uint8Array = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }
  return uint8Array
}

export const compressToBase64 = async (str) => {
  const compressed = await compress(str)
  return uint8ArrayToBase64(compressed)
}

export const decompressFromBase64 = async (base64Str) => {
  const decoded = base64ToUint8Array(base64Str)
  return decompress(decoded)
}

export const unfoldGroupedData = (data, keys) => {
  try {
    const result = []

    const unfoldObject = (obj, currentKeys) => {
      forEach(obj, (value, key) => {
        if (typeof (key) === 'string') {
          unfoldObject(value, [...currentKeys, key === 'null' ? null : key])
        } else {
          const newKeys = [...currentKeys, value]
          result.push(zipObject(keys, newKeys))
        }
      })
    }
    unfoldObject(data, [])
    return result
  } catch (ex) {
    console.error(ex)
    return null
  }
}

/**
 * This method makes grouped data with multiple inner keys flat.
 * It converts an object like this
{
  "Croissant Butter französ": {
    "161353": 32700.46
  },
  "Croissant Schoko": {
    "129623": 24693.23,
    "160223": 29.9
  }
}
into this:
{
  "Croissant Butter französ (161353)": 32700.46,
  "Croissant Schoko (129623)": 24693.23,
  "Croissant Schoko (160223)": 29.9
}
 */
export const flattenGroupedObject = (data, groupBy, customerSettings) => {
  return flatMap(data, (value, key) => {
    if (typeof (value) === 'object') {
      return ldMap(value, (subValue, subKey) => {
        const newKey = getNameWithParanthesesByTypeProps(groupBy, key, subKey, customerSettings)
        return { [newKey]: subValue }
      })
    } else {
      return { [key]: value }
    }
  }).reduce((acc, obj) => merge(acc, obj), {})
}

// FIXME: This doesn't work properly. The table still refetches aswell as the sales location collection still has to be refetched
export const getOnTagsChange = (update, queryClient, queryKey, adminQueryKey, trackEvent) => {
  return (tags, obj) => {
    const existingtIds = obj.tags.map((t) => typeof t === 'object' ? t.id : t).sort()
    const tIds = tags.map((t) => t.id).sort()
    if (!isEqual(existingtIds, tIds)) {
      update.mutateAsync({
        id: obj.id,
        tags: tIds
      }).then(() => {
        // now update all local cached data from the table so we don't need no refetch the table data
        const tagsColl = tags.map(t => pick(t, ['id', 'name', 'color']))

        const previousData = queryClient.getQueriesData({ queryKey: [adminQueryKey] })
        const newData = previousData.map(query => {
          const queryKey = query[0]
          const data = query[1]
          const updatedResults = data && data.results
            ? data.results.map((r) => {
                if (r.id === obj.id) {
                  return {
                    ...r,
                    tags: tagsColl
                  }
                }
                return r
              })
            : []
          return [queryKey, {
            ...data,
            results: updatedResults
          }]
        }).filter(i => i)
        newData.forEach(query => {
          queryClient.setQueryData(query[0], query[1])
        })

        queryClient.invalidateQueries({ queryKey: [queryKey] })
        queryClient.invalidateQueries({ queryKey: ['tags'] })
        queryClient.invalidateQueries({ queryKey: ['todo-configs'] })
        queryClient.invalidateQueries({ queryKey: ['order-configs'] })

        // trackEvent(obj, tIds)
      })
    }
  }
}

export const getInternalPathName = (pathname) => {
  return pathname.startsWith('/dashboard') ? '/dashboard' : pathname
}

export const getWithDefault = (obj, key, defaultValue) => ldDefaultTo(get(obj, key, defaultValue), defaultValue)

/**
 * Determines the displayed customer name. Necessary for operators which don't belong to that company.
 * @param {*} user The current user
 * @param {*} allCustomers The list of all customers
 */
export const getDisplayedCustomerName = (user, selectedCustomerId, allCustomers) => {
  if (user.role === OPERATOR) {
    const selectedCustomer = find(allCustomers, { id: selectedCustomerId })
    return selectedCustomer ? selectedCustomer.name : ''
  }
  return user.customer_name
}

export const loadFileAsDataURL = (file) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      }
      reject(new Error('Could not read file'))
    }

    if (file !== null) {
      reader.readAsDataURL(file)
    } else {
      resolve(null)
    }
  })
}
